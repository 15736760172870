@import '../../styles/media';

.OperatorPreview {
  &__blockHeader {
    font-size: 1.5em;
    font-weight: bold;
    margin-bottom: 40px;
  }

  &__blocks {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 24px 16px;
  }

  &__blockItem {
    &--full {
      grid-column: 1 / -1;
    }

    &--fullOnMobile {
      @include vw-small-down {
        grid-column: 1 / -1;
      }
    }
  }

  &__blockItemLabel,
  &__blockItemValue {
    margin: 0;
  }

  &__blockItemLabel {
    font-weight: bold;
    margin-bottom: 0.25em;
  }
}
