@import '../../styles/variables';
@import '../../styles/media';

.NavigationMenu {
  &__link {
    &:not(&--mobile) {
      @include vw-small-down {
          display: none;
      }
    }
    &--mobile {
      @include vw-small-up {
          display: none;
      }
    }
  }
}
