@import '../../styles/media';

.CouriersMap {
  $block: &;
  $actionsBg: #00142a;
  $actionsColor: white;
  $x-margin: 24px;
  $y-margin: $x-margin / 2;

  position: relative;
  min-height: 52px;
  width: 100%;

  &__header {
    padding: 0 16px 24px;
    pointer-events: none;

    @include vw-small-down {
      padding-top: 12px;
    }
  }

  &__counter {
    margin-top: $y-margin;
    padding: 5px 12px;
    height: fit-content;
    min-width: 173px;

    color: $actionsColor;
    background: $actionsBg;
    font-weight: 800;
    font-size: 13px;
    text-align: center;
    letter-spacing: 0.01em;

    border-radius: 50px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
  }

  &__toggle {
    margin-left: auto;
    min-width: 152px;
    height: fit-content;
    padding: 12px 10px;

    color: $actionsColor;
    background: $actionsBg;
    font-weight: 800;
    font-size: 13px;
    letter-spacing: 0.01em;
    text-align: center;
    cursor: pointer;

    border: none;
    border-radius: 0 0 4px 4px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.05);
    outline: none;

    pointer-events: all;
    transition:
      border-radius 200ms ease,
      box-shadow 200ms ease,
      color 200ms ease;

    &.toggled { border-radius: 4px; }

    &:hover {
      color: darken($actionsColor, 5%);
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.3)
    }

    @include vw-xsmall-down {
      margin-left: 0;
      margin-top: 12px;
    }
  }

  &__primaryButton {
    margin-left: auto;

    @include vw-xsmall-down {
      #{$block}__buttonText {
        display: none;
      }
    }
  }

  header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    display: flex;
    flex-wrap: wrap;
  }

  main {
    position: relative;
    height: 400px;
    width: 100%;

    transition: height 200ms ease;

    &.hidden {
      height: 52px;
    }
  }
}
