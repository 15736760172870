@import '../../styles/media';

.Couriers {
  @include vw-small-down {
    html:root & {
      padding-top: 0;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;

    @include vw-medium-down {
      align-items: flex-end;
      flex-direction: column;
    }

    @include vw-small-down {
        align-items: flex-start;
    }
  }

  &__radios {
    @include vw-xsmall-down {
      html:root & {
        display: flex;
        width: 100%;
      }
    }
  }

  &__radio {
    @include vw-xsmall-down {
      html:root & {
        align-items: center;
        display: flex;
        justify-content: center;
        height: 40px;
        text-align: center;
        width: 50%;
      }
    }
  }

  &__statistic {
    min-height: 48px;
    display: flex;

    @include vw-xxlarge-down {
      display: grid;
      grid-gap: 24px;
      grid-template-columns: repeat(2, 1fr);
    }

    @include vw-medium-down {
      grid-gap: 8px;
      grid-template-columns: repeat(4, auto);
      margin: 16px 0;
    }

    @include vw-xsmall-down {
      justify-content: space-between;
      width: 100%;
    }

    @media (max-width: 375px - 1) {
      grid-gap: 12px;
    }

    .ant-statistic-title {
      html:root & {
        @include vw-medium-down {
          font-size: 12px;
        }
      }
    }

    .ant-statistic-content {
      html:root & {
        @include vw-medium-down {
          margin-right: 0;
        }
      }
    }
  }

  &__table {
    overflow-x: auto;

    @include vw-medium-down {
      margin-top: 24px;
    }

    table {
      background-color: white;
    }
  }

  &__buttons {
    @include vw-xlarge-down {
      align-items: flex-end;
      display: flex;
      flex-direction: column;
    }

    @include vw-small-down {
        align-items: flex-start;
    }

    @include vw-xsmall-down {
        width: 100%;
    }
  }

  &__button {
    @include vw-xsmall-down {
      html:root & {
        height: 40px;
        width: 100%;
      }
    }

    &:not(:last-child) {
      margin-right: 8px;

      @include vw-xlarge-down {
        margin-bottom: 8px;
        margin-right: 0;
      }
    }
  }

  &__statisticItem {
    html:root & {
      margin: 0 8px;

      @include vw-xxlarge-down {
        align-items: center;
        display: flex;
        flex-direction: column;
        margin: 0;
        text-align: center;
      }

      @include vw-small-down {
        align-items: flex-start;
        justify-self: start;
        text-align: left;
      }
    }

    .ant-statistic-title {
      margin-bottom: 0;
    }

    .ant-statistic-content {
      width: fit-content;
      margin: 0 auto;
      font-size: 18px;

      @include vw-small-down {
          margin-left: 0;
      }
    }
  }
}

// TODO: сделать комноненту таблицы свои стили
.CouriersTable {
  $block: &;

  &__actions {
    display: flex;

    @include vw-small-down {
        justify-content: flex-end;
    }
  }

  &__action {
    &:not(:last-child) {
      margin-right: 8px;
    }
  }

  &__actionCell {
    text-align: right;
    white-space: nowrap;
    > *:not(h3) {
      margin: 0 4px;

      @include vw-xsmall-down {
          margin: 0;
      }
    }
  }

  border: 1px solid hsl(0, 0%, 94%);
  border-bottom: none;

  td#{$block}__latenessCell,
  td#{$block}__balanceCell,
  td#{$block}__completedOrdersCell {
    font-weight: bold;
  }
}

.ResponsiveTable {
  $block: &;

  &--couriers {
    @include vw-small-down {
      html:root & {
        tr {
          grid-template-columns: repeat(2, auto);

          > td {
            &:nth-child(2) {
              grid-column: 1 / -1;
              grid-row: 2;
            }

            &:nth-child(3) {
              text-align: right;
            }

            &:nth-child(4) {
              grid-column: 1 / -1;
            }

            &:nth-child(6) {
              grid-column: 2;
              border-bottom: none;
            }

            &:nth-child(n + 4) {
              display: flex;
              flex-direction: column;
              justify-content: space-between;
            }

            @include vw-xsmall-down {
              &:nth-child(1),
              &:nth-child(2),
              &:nth-child(3) {
                #{$block}__mobileHeading {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }
}
