@import 'styles/variables';
@import 'styles/media';

label {
  html:root & {
    font-weight: 500;
  }
}

.App {
  $sider-mobile-bp: 360px;
  text-align: center;
  &__test {
    width: 10px;
    height: 10px;
    background-color: red;
  }

  &__sider {
    background-color: #030d1a;

    @media (max-width: $sider-mobile-bp) {
      $xsmall-width: 235px;
      html:root & {
        flex: 0 0 $xsmall-width !important;
        min-width: $xsmall-width !important;
        max-width: $xsmall-width !important;
        width: $xsmall-width !important;
      }
    }

    &.ant-layout-sider-collapsed {
      @include vw-medium-down {
        display: none;
      }
    }

    &:not(.ant-layout-sider-collapsed) {
      + %app-layout-wrapper {
        &::before {
          opacity: 1;
          pointer-events: all;
        }
      }
    }
  }

  &__layoutWrapper {
    @extend %app-layout-wrapper !optional;
    @include vw-xsmall-down {
      position: relative;
      &::before {
        background-color: transparentize(black, 0.2);
        content: "";
        height: 100%;
        left: 0;
        opacity: 0;
        pointer-events: none;
        position: absolute;
        top: 0;
        transition: opacity 0.25s ease-out;
        width: 100%;
        z-index: $z-index-layout-wrapper;
      }
    }
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.App-loader {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0c0b0b;
  flex-direction: column;

  &__adaptive {
    height: 100%;
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  &__text {
    margin-top: 0.5em;
    font-size: 1.25em;
    color: white;
  }
}

.Sidebar {
  &__homeLink {
    display: flex;
    justify-content: center;
    padding-top: 18px;
    padding-bottom: 18px;

    img {
      transition: width 0.25s;
      width: 208px;
      height: 30px;
    }
  }
}

.ant-layout-sider-collapsed {
  .Sidebar__homeLink {
    img {
      width: 49px;
      height: 29px;
    }
  }
}

.ProfileLink {
  &__name {
    margin-right: 8px;
  }
}

.AppSection {
  padding: 16px 16px 24px;
}

.OrganizationsTable {
  &__priceColumn {
    html:root & {
      text-align: right;
      @include vw-small-down {
        text-align: left;
      }
    }
  }

  &__actionsColumn {
    min-width: 40px;
    text-align: center;
    @include vw-small-down {
        text-align: left;
    }
  }

  &__agentColumn {
    min-width: 215px;
  }

  &__agentName {
    margin: 0;
  }

  &__mainBranchPhone,
  &__agentPhone {
    margin: 0;

    span {
      margin-right: 4px;
    }
  }

  &__mainBranch {
    margin: 0;
  }

  &__mainBranchAddress {
    margin: 0;
  }

  &__mainBranchPhone {
    display: inline-block;
    margin-bottom: 4px;
  }

  &__secondaryBranch {
    margin: 0;

    &:not(:last-child) {
      margin-bottom: 4px;
    }
  }
}

.BranchForm {
  &__add,
  &__delete {
    display: block;
    margin: 0 auto;
  }
}

.RateVariant {
  &__rateType {
    display: block;
    text-align: center;
  }

  &__fields {
    text-align: center;
  }

  &__column {
    width: 50%;

    @include vw-medium-down {
      width: 100%;
    }
  }
}

.HideNowButton {
  .ant-picker-now-btn {
    display: none;
  }
}

.CustomerSearchInput {
  &__dropdown {
    html:root {
      max-width: 100%;
    }
  }
}

.CounterTag {
  html:root & {
    margin-right: 0;
    padding: 0 5px;
    line-height: 16px;

    @include vw-xsmall-down {
      position: absolute;
      right: -6px;
      top: -10px;
    }
  }
}
