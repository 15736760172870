@import '../../styles/media';

.Auth {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  &__form {
    width: 600px;
    height: 200px;
    html:root & {
      margin-left: auto;
      margin-right: auto;
    }

    @include vw-medium-down {
      max-width: 80%;
    }

    @include vw-small-down {
        max-width: 90%;
    }
  }

  &__splash {
    background-color: #030d1a;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 40px;
    flex-grow: 1;
    height: 100%;
    max-height: 500px;
    min-height: 370px;
    background-image: url('../../assets/auth-bg.svg');
    background-size: cover;
    background-position: bottom center;

    @include vw-medium-down {
      margin-bottom: 32px;
      min-height: 240px;
      max-height: 320px;
    }

    @include vw-small-down {
      margin-bottom: 24px;
      max-height: 280px;
    }
  }

  &__title {
    color: white;
    font-size: 36px;
    line-height: 1.15;
    font-weight: bold;
    width: 100%;
    text-align: center;

    @include vw-medium-down {
      font-size: 32px;
    }

    @include vw-small-down {
      font-size: 24px;
    }
  }

  &__logo {
    display: block;
    width: 400px;
    margin: 0 auto 40px;

    @include vw-medium-down {
      margin-bottom: 20px;
      max-width: 80%;
    }

    @include vw-small-down {
      margin-bottom: 12px;
    }
  }

  &__control {
    html:root & {
      margin-bottom: 0;
    }

    & + & {
      margin-top: 16px;

      @include vw-small-down {
        margin-top: 12px;
      }
    }
  }

  &__buttonWrapper {
    display: flex;
    justify-content: center;
    margin-top: 24px;

    @include vw-small-down {
      width: 100%;
    }
  }

  &__button {
    @include vw-small-down {
        width: 100%;
    }
  }

  .ant-form-item-label {
    html:root & {
      @include vw-small-down {
        padding-bottom: 4px;
      }

      > label {
        @include vw-small-down {
          height: auto;
          font-size: 16px;
        }
      }
    }
  }

  &__footer {
    margin: 24px auto;
    text-align: center;
  }

  &__footerText {
    color: #3d4752;
  }

  &__footerLink {
    text-decoration: underline;
    color: #eb463d;

    &:hover,
    &:focus,
    &:active {
      text-decoration: underline;
    }
  }
}
