@import '../../styles/media';

.CreateOrder {
  &__grid {
    display: grid;
    grid-gap: 24px;
    grid-template-columns: repeat(2, 1fr);

    @include vw-medium-down {
      grid-gap: 16px;
    }

    @include vw-small-down {
      grid-template-columns: 1fr;
      grid-gap: 12px;
    }
  }

  &__column {
    max-width: 500px;
  }

  &__formColumn {
    width: 50%;

    &.toThree {
      width: 33%;
    }

    @include vw-small-down {
      width: 100%;
    }
  }

  &__singleInput {
    html:root & {
      @include vw-small-up {
        width: calc(50% - 12px);
      }
    }
  }

  &__payedStatusSwitcher {
    //display: flex;
    //align-items: center;
  }

  &__payedStatusLabel {
    font-weight: bold;
    font-size: 14px;
    margin-left: 12px;
  }

  &__groupWrapper {
    display: grid;
    grid-gap: 16px;
    grid-template-columns: repeat(2, 1fr);
  }

  &__dateError {
    margin-bottom: 12px;

    @include vw-small-down {
      margin-bottom: 8px;
    }
  }
}

html:root {
  .CreateOrder {
    $block: &;

    &__address {
      max-width: 100%;

      .ant-select-selection-item {
        white-space: initial;
      }

      & > * {
        max-width: 100%;
      }
    }

    &__addressType {
      &,
      & > .ant-form-item-label {
        text-align: center;
      }
    }

    &__actionButton {
      display: block;
      margin: auto auto 0;

      @include vw-xsmall-down {
          width: 100%;
      }
    }

    &__paragraph {
      margin-bottom: 8px;
    }

    .ant-card-body {
      @include vw-large-down {
        display: flex;
        flex-direction: column;
        height: 100%;
      }

      h2 {
        margin-bottom: 0;
      }
    }

    @include vw-medium-down {
      .ant-card-body {
        align-items: stretch;
        padding: 16px 20px;
      }
    }

    @include vw-small-down {
      padding-top: 0;

      .ant-card-body {
        padding: 12px 16px;

        h2 {
          font-size: 16px;
        }
      }

      .ant-divider-horizontal {
        margin: 8px 0;
      }

      .ant-form-item {
        margin-bottom: 12px;

        &.ant-row {
          > * {
            flex: auto;
          }
        }
      }

      .ant-form-item-label {
        padding-bottom: 4px;
      }
    }

    @include vw-xsmall-down {
      .ant-form-item-label {
        text-align: left;
      }

      .ant-radio-group {
        display: flex;
        width: 100%;

        > .ant-radio-button-wrapper {
          width: 50%;
        }
      }
    }
  }
}
