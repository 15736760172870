.PushNotification {
  &__formItem {
    &:not(:last-child) {
      margin-bottom: 1em;
    }
  }

  &__formTitle {
    margin-bottom: 0.25em;
  }
}
