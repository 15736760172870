.OperatorForm {
  &__card {
    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }

  &__header {
    html:root & {
      margin-bottom: 0;
    }
  }

  &__grid {
    display: grid;
    grid-gap: 16px;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;

    @media (max-width: 640px) {
      display: block;
    }
  }

  &__gridItem {
    &--full {
      grid-column: 1 / -1;
    }
  }

  &__submit {
    margin-top: 16px;
  }
}
