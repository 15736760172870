@import '../../styles/media';

.OperatorsStatistic {
  display: grid;
  grid-gap: 24px;
  grid-template-columns: repeat(2, 1fr);
  min-height: 48px;

  @include vw-xsmall-down {
    justify-content: center;
    grid-template-columns: repeat(2, auto);
    width: 100%;
  }

  .ant-statistic-title {
    html:root & {
      text-align: center;

      @include vw-medium-down {
        font-size: 12px;
        text-align: right;
      }

      @include vw-xsmall-down {
          text-align: center;
      }
    }
  }

  .ant-statistic-content {
    html:root & {
      width: fit-content;
      margin: 0 auto;
      font-size: 18px;

      @include vw-xsmall-up {
        @include vw-large-down {
          margin-right: 0;
        }
      }
    }
  }
}
