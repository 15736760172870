.CourierListSelect {
  width: 100%;

  &__replenish {
    margin-top: 0.75em;
    display: flex;
    flex-direction: column;

    &Label {
      flex-shrink: 1;
    }

    &Controls {
      margin-top: 0.25em;
      display: flex;
      justify-content: space-between;
    }

    &Input {
      width: 20em !important;
    }
  }
}
