@import '../../styles/media';

.OperatorsTable {
  &__phone {
    @include vw-xsmall-up {
        display: none;
    }
  }
}

.ResponsiveTable {
  $table: &;

  &--operators {
    @include vw-xsmall-down {
      html:root:root & {
        tr {
          grid-template-columns: repeat(2, auto);

          td {
            &:first-child {
              align-items: center;
              display: flex;

              #{$table}__mobileHeading {
                display: none;
              }
            }

            &:nth-child(2) {
              grid-column: 1 / -1;
              grid-row: 3;
            }

            &:nth-child(3) {
              grid-column: 1 / -1;
              grid-row: 2;
            }

            &:nth-child(4) {
              display: none;
            }

            &:nth-child(5) {
              grid-row: 4;
            }

            &:nth-child(6) {
              grid-row: 4;
              text-align: right;
            }

            &:last-child {
              border-bottom: 1px solid #cfd0d2;
              grid-column: 2;
              grid-row: 1;

              & > * {
                text-align: right;
              }
            }
          }
        }
      }
    }
  }
}
