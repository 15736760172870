$breakpoint-xxsmall: 375px !default;
$breakpoint-xsmall: 425px !default;
$breakpoint-small: 640px !default;
$breakpoint-medium: 900px !default;
$breakpoint-large: 1000px !default;
$breakpoint-xlarge: 1200px !default;
$breakpoint-xxlarge: 1440px !default;

@function vw-from($bp) {
  @return (min-width: $bp);
}

@function vw-to($bp) {
  @return (max-width: $bp - 1);
}

@mixin vw-xxsmall-down {
  @media #{vw-to($breakpoint-xxsmall)} {
    @content;
  }
}

@mixin vw-xxsmall-up {
  @media #{vw-from($breakpoint-xxsmall)} {
    @content;
  }
}

@mixin vw-xsmall-down {
  @media #{vw-to($breakpoint-xsmall)} {
    @content;
  }
}

@mixin vw-xsmall-up {
  @media #{vw-from($breakpoint-xsmall)} {
    @content;
  }
}

@mixin vw-small-down {
  @media #{vw-to($breakpoint-small)} {
    @content;
  }
}

@mixin vw-small-up {
  @media #{vw-from($breakpoint-small)} {
    @content;
  }
}

@mixin vw-small-only {
  @media #{vw-from($breakpoint-xsmall)} and #{vw-to($breakpoint-small)} {
    @content;
  }
}

@mixin vw-medium-down {
  @media #{vw-to($breakpoint-medium)} {
    @content;
  }
}

@mixin vw-medium-up {
  @media #{vw-from($breakpoint-medium)} {
    @content;
  }
}

@mixin vw-medium-only {
  @media #{vw-from($breakpoint-small)} and #{vw-to($breakpoint-medium)} {
    @content;
  }
}

@mixin vw-large-down {
  @media #{vw-to($breakpoint-large)} {
    @content;
  }
}

@mixin vw-large-up {
  @media #{vw-from($breakpoint-large)} {
    @content;
  }
}

@mixin vw-large-only {
  @media #{vw-from($breakpoint-medium)} and #{vw-to($breakpoint-large)} {
    @content;
  }
}

@mixin vw-xlarge-only {
  @media #{vw-from($breakpoint-large)} and #{vw-to($breakpoint-xlarge)} {
    @content;
  }
}

@mixin vw-xlarge-down {
  @media #{vw-to($breakpoint-xlarge)} {
    @content;
  }
}

@mixin vw-xlarge-up {
  @media #{vw-from($breakpoint-xlarge)} {
    @content;
  }
}

@mixin vw-xxlarge-down {
  @media #{vw-to($breakpoint-xxlarge)} {
    @content;
  }
}

@mixin vw-xxlarge-up {
  @media #{vw-from($breakpoint-xxlarge)} {
    @content;
  }
}
