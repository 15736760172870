.Settings {
  &__container {
    padding: 16px 16px 24px;
  }

  &__formGrid {
    display: grid;
    grid-gap: 16px;
    grid-template-columns: repeat(2, 1fr);

    @media (max-width: 640px) {
      display: block;
    }
  }
}
