@import '../../styles/media';

.CouriersForm {
  @include vw-small-down {
      html:root & {
        padding-top: 0;
      }
  }

  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    justify-content: space-between;
    width: 100%;

    @include vw-small-down {
      margin-bottom: 0;
    }

    @include vw-xsmall-down {
      align-items: flex-start;
      flex-direction: column;
    }
  }

  &__headerLeft {
    display: flex;
    align-items: center;
  }

  &__headerBack {
    html:root & {
      color: black;
      margin: 0;
      padding: 0 8px 0 0;

      @include vw-small-down {
        height: auto;
        margin-top: -2px;
      }
    }
  }

  &__headerTitle {
    margin: 0;
    height: 36px;

    @include vw-small-down {
        height: auto;
    }
  }

  &__column {
    width: 50%;

    @include vw-small-down {
      width: 100%;
    }
  }

  &__submitButton {
    @include vw-xsmall-down {
        width: 100%;
    }

    &:not(&--mobile) {
      @include vw-small-down {
        html:root & {
          display: none;
        }
      }
    }

    &--mobile {
      @include vw-small-up {
        html:root & {
          display: none;
        }
      }
    }
  }

  html:root & {
    .ant-card-body {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      height: 100%;

      h2 {
        margin-bottom: 0;
      }
    }

    @include vw-medium-down {
      .ant-card-body {
        align-items: stretch;
        padding: 16px 20px;
      }
    }

    @include vw-small-down {
      padding-top: 0;

      .ant-card-body {
        padding: 12px 16px;

        h2 {
          font-size: 16px;
        }
      }

      .ant-divider-horizontal {
        margin: 8px 0;
      }

      .ant-form-item {
        margin-bottom: 12px;

        &.ant-row {
          > * {
            flex: auto;
          }
        }
      }

      .ant-form-item-label {
        padding-bottom: 4px;
      }
    }

    @include vw-xsmall-down {
      .ant-form-item-label {
        text-align: left;
      }

      .ant-radio-group {
        display: flex;
        width: 100%;

        > .ant-radio-button-wrapper {
          display: flex;
          justify-content: center;
          width: 50%;

          @media (max-width: 375px - 1) {
            font-size: 12px;
            padding: 0 4px;
          }
        }
      }
    }
  }
}
