@import '../../styles/media';

.StatisticsTable {
  border: 1px solid hsl(0, 0%, 94%);
  border-bottom: none;

  @include vw-small-up {
    overflow-x: auto;
  }

  html:root & {
    table {
      background-color: white;

      @include vw-xsmall-down {
        tr {
          @include vw-xsmall-down {
            grid-template-columns: repeat(2, auto);
          }

          td {
            h3 {
              margin-bottom: 8px;
            }

            &:first-child {
              h3 {
                display: none;
              }
            }

            &:last-child {
              grid-column: 1 / -1;
            }

            &:nth-child(even) {
              text-align: right;
            }

            &:last-child {
            }
          }
        }
      }
    }
  }
}
