@import '../../styles/variables';
@import '../../styles/media';

.News {
  @include vw-small-down {
      html:root & {
        padding-top: 0;
      }
  }

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  min-height: calc(100vh - #{$header-height});

  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  &__addArticleButton {
    margin-bottom: 24px;
    width: fit-content;

    @include vw-xsmall-down {
      html:root & {
        height: 40px;
        width: 100%;
      }
    }
  }
}

.CardWrapper,
.ArticleCard {
  html:root:root & {
    @include vw-small-down {
      margin-bottom: 12px;
      margin-left: 0;

      .ant-card-head {
        padding: 0 12px;

        .ant-card-extra {
          padding-bottom: 8px;

          > span {
            align-items: flex-start;
            display: flex;
            flex-direction: column;
          }
        }
      }

      .ant-card-body {
        padding: 8px 12px;
      }
    }
  }
}

.ArticleCard {
  html:root & {
    max-width: 100%;
    width: 600px;
    margin-bottom: 24px;

    @include vw-medium-down {
      margin-bottom: 16px;
    }

    @include vw-small-down {
      margin-bottom: 12px;
    }

    // no idea how to do it properly (Ant library seems like no provide functionality to style this)
    .ant-card-head-wrapper {
      @include vw-medium-down {
        align-items: flex-start;
        flex-direction: column;

        & > * {
          margin-left: 0;
          padding: 16px 0 0;

          &:not(:first-child) {
            padding: 8px 0 16px;
          }
        }
      }
    }
  }

  .ant-card-head-title {
    @include vw-medium-down {
      white-space: normal;
    }
  }

  &__author {
    font-weight: bold;
  }

  &__separator {
    margin-left: 8px;
    margin-right: 8px;

    @include vw-small-down {
        display: none;
    }
  }
}

.CardWrapper {
  position: relative;
  margin-left: 1em;
  margin-bottom: 2em;

  &__badge {
    position: absolute;
    top: -1em;
    left: -1em;
    border-radius: 2em !important;
    z-index: 2;

    @include vw-small-down {
      left: 8px;
      top: 0;
      transform: translateY(-50%);
    }
  }
}
