@import '../../styles/media';

.Operators {
  &__container {
    padding: 16px 16px 24px;
  }

  &__togglers {
    &,
    .ant-radio-group,
    .ant-radio-button-wrapper {
      @include vw-xsmall-down {
        text-align: center;
        width: 100%;
      }
    }
  }

  &__statistic {
    min-height: 56px;

    @include vw-medium-down {
        margin: 16px 0;
    }

    @include vw-xsmall-down {
        width: 100%;
    }
  }

  &__header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;

    @include vw-medium-down {
      align-items: flex-end;
        flex-direction: column;
    }
  }
}
