.CourierPreview {
  &__drawer {
    z-index: 1001;
    .ant-drawer-content {
      background-color: #fafafa;
    }

    .ant-drawer-content-wrapper {
      max-width: 90%;
    }
  }
}
