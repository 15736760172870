@import '../../styles/media';

.Statistics {
  $items-vertical-gutter: 16px;

  @include vw-small-down {
    html:root & {
      padding-top: 0;
    }
  }

  > .ant-divider {
    html:root & {
      @include vw-small-down {
        margin: 8px 0;
      }

      &::before {
        display: none;
      }

      .ant-divider-inner-text {
        padding-left: 0;
      }
    }
  }

  &__header {
    margin-bottom: 24px;
  }

  &__headerRow {
    @include vw-small-down {
      display: flex;
    }
  }

  &__row {
    margin-bottom: -$items-vertical-gutter;

    @include vw-xsmall-down {
      margin-bottom: -8px;
    }
  }

  &__column {
    margin-bottom: $items-vertical-gutter;

    @include vw-xsmall-down {
      margin-bottom: 8px;
    }
  }

  &__cardWrapper {
    height: 100%;

    .ant-card-body {
      @include vw-small-down {
        padding: 12px;
      }

      @include vw-xsmall-down {
          padding: 8px;
      }
    }

    .ant-statistic-title {
      html:root & {
        @include vw-small-down {
          font-size: 12px;
        }
      }
    }

    .ant-statistic-content {
      html:root & {
        @include vw-small-down {
          font-size: 18px;
          line-height: 1;
        }
      }
    }
  }
}

.StatsFiltersForm {
  display: flex;
  flex-wrap: wrap;

  @include vw-small-down {
    align-items: flex-start;
    flex-direction: column;
  }

  @include vw-xsmall-down {
      align-items: stretch;
  }

  html:root & {
    @include vw-xsmall-down {
      .ant-form-item-label {
        line-height: 1.25;
        padding-bottom: 4px;
      }
    }
  }

  &__item {
    @include vw-xsmall-down {
        width: 100%;
    }

    &:not(:last-child) {
      html:root & {
        margin-right: 16px;

        @include vw-small-down {
          margin-bottom: 8px;
          margin-right: 0;
        }

        @include vw-xsmall-down {
          margin-bottom: 4px;
        }
      }
    }

    &--organization {
      width: 100%;
      max-width: 256px;

      @include vw-xsmall-down {
          max-width: none;
      }
    }

    &--submit {
      width: 100%;

      html:root & {
        @include vw-small-down {
          margin-top: 16px;
        }
      }
    }

    &--branch {
      min-width: 320px;
    }

    &--status {
      min-width: 125px;
    }
  }

  &__select,
  &__dropdown {
    html:root & {
      @include vw-xsmall-down {
        max-width: 100%;
        min-width: auto !important;
        width: 100% !important;
      }
    }
  }

  &__submit {
    width: 100%;

    html:root & {
      @include vw-xsmall-down {
        margin-bottom: 8px;
        margin-top: 8px;

        &,
        .ant-btn-primary {
          height: 40px;
          width: 100%;
        }
      }
    }
  }
}
