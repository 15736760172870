@import '../../styles/media';

.CourierProfilePreview {
  $borderStyle: 1px solid #f0f0f0;

  &__block {
    padding: 1em 0;
    &:not(:last-child) {
      border-bottom: $borderStyle;
    }
  }

  &__blockHeader {
    font-size: 1.5em;
    font-weight: bold;
    margin-bottom: 0.5em;
  }

  &__blockContent {
    display: flex;
    flex-flow: row wrap;
  }

  &__blockItem {
    flex: 50%;
    margin-bottom: 0.75em;

    @include vw-xsmall-down {
        flex: 100%;

      &:first-child {
        order: -4;
      }

      &:nth-child(3) {
        order: -3;
      }

      &:nth-child(5) {
        order: -2;
      }

      &:nth-child(6) {
        order: -1;
      }
    }

    &--single {
      flex: 100%;
    }

    &--thirds {
      flex: 33.3333333%;

      @include vw-small-down {
          flex: 50%;
      }

      @include vw-xsmall-down {
        flex: 100%;
      }
    }
  }

  &__blockItemLabel {
    font-weight: bold;
    margin-bottom: 0.25em;
  }

  &__blockItemContent {
  }

  &__transactions {
    width: 100%;

    .ant-table-content {
      overflow-x: auto;
    }

    table {
      @include vw-small-up {
        border-left: $borderStyle;
        border-right: $borderStyle;
      }

      tr {
        > td {
          html:root & {
            @include vw-small-down {
              padding: 12px;
            }
          }
        }
      }
    }
  }
}
