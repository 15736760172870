.AddressSearchInput {
  &__option {
    display: flex;
  }

  &__distance {
    display: block;
    margin-right: 10px;
    color: black;
  }
}
