%order-preview-label {
  color: rgba(0, 0, 0, 0.65);
  font-size: 1.25em;
  font-weight: bold;
}

%order-preview-heading {
  color: #1f1f1f;
  font-size: 14px;
  font-weight: bold;
}

.OrderPreview {
  &__id {
    font-size: 1.5em;
    font-weight: bold;
  }

  &__blocks {
    display: grid;
    grid-gap: 35px 80px;
    grid-template-columns: repeat(2, 1fr);
    margin-top: 32px;

    @media (max-width: 640px) {
      grid-template-columns: 1fr;
    }
  }

  &__map {
    margin-top: 24px;
  }

  &__totals {
    display: grid;
    grid-gap: 24px;
    grid-template-columns: repeat(2, 1fr);
    margin-top: 32px;

    @media (max-width: 640px) {
      grid-template-columns: 1fr;
    }
  }
}

.OrderPreviewBlock {
  &__label {
    @extend %order-preview-label;
  }

  &__heading {
    @extend %order-preview-heading;

    margin-top: 4px;
  }

  &__address {
    font-size: 14px;
    color: #636363;
    font-weight: bold;
    line-height: 21px;
  }

  &__tag {
    min-width: 116px;
    text-align: center;
  }

  &__phone {
    display: inline-block;
    font-size: 14px;
  }

  &__time {
    color: #636363;
    font-size: 12px;
    font-weight: bold;
  }

  &__comment {
    color: #636363;
    font-size: 14px;
  }
}

.OrderPreviewTotals {
  &__label {
    @extend %order-preview-label;
  }

  &__text {
    @extend %order-preview-heading;
    font-weight: normal;
  }

  &__list {
    list-style: none;
    margin: 0;
    padding: 0;
    text-align: right;

    @media (max-width: 640px) {
      text-align: left;
    }
  }

  &__listItem {
    &:not(:last-child) {
      margin-bottom: 4px;
    }
  }

  &__status {
    margin: 4px 0 10px;
  }
}
