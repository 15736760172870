@import '../../styles/variables';
@import '../../styles/media';

.HeaderTopBar {
  html:root & {
    background-color: hsl(210, 29%, 97%);
    padding: 10px;
    height: 65px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include vw-medium-down {
      align-items: stretch;
      height: auto;
    }

    @include vw-xsmall-down {
      flex-direction: column;
    }
  }

  &__leftSection {
    display: flex;
    align-items: center;
    line-height: 1;

    @include vw-xsmall-down {
      align-items: flex-start;
      flex-direction: column;
      position: relative;
    }
  }

  &__phoneContainer {
    @include vw-xsmall-down {
      align-self: flex-end;
    }
  }

  &__phoneLabel {
    font-size: 12px;
    font-weight: bold;
    color: hsl(0, 0%, 39%);
    margin: 0 0 7px;

    @include vw-xsmall-down {
        text-align: right;
    }
  }

  &__phone {
    svg {
      margin-right: 4px;
    }
  }

  &__navToggle {
    margin-right: 24px;
    margin-left: 6px;
    position: relative;
    z-index: $z-index-header-nav-toggler;

    html:root & {
      @include vw-xsmall-down {
        left: 0;
        position: absolute;
        top: 0;
      }
    }
  }

  &__dateTime {
    line-height: 1;
    text-align: right;
    margin-right: 16px;

    @include vw-medium-down {
      margin-right: 0;
    }
  }

  &__rightSection {
    display: flex;
    line-height: 1;
    align-items: center;

    @include vw-medium-down {
      align-items: flex-end;
      flex-direction: column;
    }

    @include vw-xsmall-down {
      margin-top: 12px;
    }
  }

  &__currentDate,
  &__currentTime {
    margin: 0;
    font-size: 12px;
    font-weight: bold;
    color: hsl(0, 0%, 39%);

    svg {
      margin-right: 8px;
    }
  }

  &__currentDate {
    margin-bottom: 4px;
  }

  &__link {
    html:root & {
      @include vw-medium-down {
        padding-right: 0;
      }
    }
  }
}
