@import '../../styles/media';

.Orders {
  &__container {
    padding: 16px 16px 24px;
  }

  &__header {
    display: flex;
    align-items: flex-start;
    margin-bottom: 24px;
    justify-content: space-between;

    @include vw-large-down {
      align-items: flex-start;
      flex-direction: column;
    }

    @include vw-xsmall-down {
      margin-bottom: 0;
    }
  }

  &__filters {
    display: flex;
    flex-grow: 1;

    @include vw-large-down {
      margin-bottom: 16px;
    }

    @include vw-xsmall-down {
      flex-direction: column;
      margin-bottom: 8px;
      width: 100%;
    }
  }

  &__radioButtons {
    @include vw-xsmall-down {
      html:root & {
        display: flex;
        margin-top: 24px;
      }
    }
  }

  &__radioButton {
    $loop-items-length: 5;
    @include vw-xsmall-down {
      html:root & {
        align-items: center;
        display: flex;
        justify-content: center;
        height: 40px;
        width: 100%;
      }
    }

    @include vw-xxsmall-down {
      html:root & {
        padding: 0 10px;
      }
    }

    @for $i from 1 through 5 {
      html:root & {
        &:nth-child(#{$i}) {
          z-index: $loop-items-length - $i + 1;
        }
      }
    }
  }

  &__radioIcon {
    margin-right: 8px;
    @include vw-xsmall-down {
      margin-right: 0;
    }
  }

  &__radioText {
    margin-right: 8px;

    @include vw-xsmall-down {
      display: none;
    }
  }

  &__reloadButton {
    margin-left: 8px;

    @include vw-xsmall-down {
      margin-top: 12px;
      margin-left: 0;
      order: -1;

      &,
      button {
        min-height: 40px;
      }

      &,
      & > * {
        width: 100%;
      }
    }
  }

  &__createButtonWrapper,
  &__createButton {
    @include vw-xsmall-down {
      min-height: 40px;
      width: 100%;
    }
  }

  &__createButtonWrapper {
    @include vw-large-up {
      margin-left: 16px;
    }

    @include vw-xsmall-down {
      order: -1;
    }
  }
}

.OrdersTable {
  $block: &;
  border: 1px solid hsl(0, 0%, 94%);
  border-bottom: none;

  @include vw-small-up {
    overflow-x: auto;
  }

  table {
    background-color: white;

    tr {
      td {
        &:last-child {
          @include vw-small-down {
            grid-column: 1 / -1;
          }
        }
      }
    }
  }

  &__organizationTitle,
  &__clientName,
  &__courierName,
  &__deliveryPrice {
    font-weight: bold;
    font-size: 12px;
    color: hsl(0, 0%, 12%);
    margin: 0;
  }

  &__courierPhone {
    white-space: nowrap;
  }

  &__deliveryPrice {
    font-weight: normal;

    @include vw-small-down {
      font-size: 14px;
    }
  }

  &__organizationAddress,
  &__clientAddress,
  &__paymentMethod {
    font-size: 12px;
    font-weight: bold;
    color: hsl(0, 0%, 39%);
    margin: 0;
  }

  &__deliveryTimeFrom,
  &__deliveryTimeTo {
    margin: 0;
    font-size: 12px;
    color: hsl(0, 0%, 39%);
    font-weight: bold;
  }

  &__courier {
    display: flex;
    align-items: center;
  }

  &__courierMore {
    margin-left: 0.5em;

    html:root & {
      border: none;
      box-shadow: none;
    }
  }

  &__clientAddress {
    max-width: 250px;
  }

  &__actions {
    min-width: 72px;
    text-align: right;

    @include vw-small-down {
      text-align: left;
    }
  }

  &__action {
    &:not(:first-child) {
      margin-left: 8px;

      @include vw-xxsmall-down {
        margin-left: 0;
        margin-top: 4px;
      }
    }
  }
}

.ResponsiveTable {
  $block: &;

  &--orders {
    @include vw-xsmall-down {
      #{$block}__mobileHeading {
        display: none;
      }

      html:root:root & {
        tr {
          grid-template-columns: repeat(4, 1fr);

          > td {
            grid-column: span 2;
            padding: 6px;

            &:first-child {
              grid-column: 1;

              & > * {
                height: 100%;
              }
            }

            &:nth-child(2),
            &:nth-child(3) {
              grid-column: 1 / -1;
            }

            &:nth-child(4),
            &:nth-child(6) {
              border-bottom: none;
            }

            &:nth-child(5) {
              align-items: center;
              display: flex;
              justify-content: center;
              grid-column: 2 / span 2;
              grid-row: 1;

              .ant-tag {
                margin-right: 0;
              }

              .OrdersTable__courierMore {
                $size: 12px;

                height: $size;
                margin-left: 0;
                min-width: $size;
                padding: 2px 8px;
                width: $size;
              }
            }

            &:last-child {
              align-items: center;
              border-bottom: 1px solid #cfd0d2;
              display: flex;
              grid-column: 4;
              grid-row: 1;

              & > * {
                text-align: right;

                @include vw-xxsmall-down {
                  display: grid;
                  justify-content: end;
                  grid-template-rows: auto auto;
                }
              }
            }
          }
        }
      }
    }
  }
}
