@import '../../styles/media';

.ResponsiveTable {
  $block: &;

  &__mobileHeading {
    color: #b5b5b5;
    font-size: 16px;
    line-height: 1;
    margin-bottom: 12px;

    @include vw-small-up {
      display: none;
    }

    @include vw-small-down {
      font-size: 14px;
      margin-bottom: 8px;
    }
  }

  &__idWrapper {
    align-items: center;
    display: flex;

    #{$block}__mobileHeading {
      margin-bottom: 0;
    }
  }

  &__id {
    @include vw-small-down {
      html:root & {
        margin-left: 4px;
      }
    }
  }

  @include vw-small-down {
    html:root & {
      table {
        background-color: #f6f8fa;
        display: flex;
      }

      thead {
        display: none;
      }

      tbody {
        width: 100%;
      }

      tr {
        background-color: white;
        border: 1px solid #dcdcdc;
        display: grid;
        grid-template-columns: repeat(2, 1fr);

        @include vw-xsmall-down {
          grid-template-columns: 1fr;
        }

        &:not(:last-child) {
          margin-bottom: 16px;
        }

        > td {
          border: none;
          padding: 8px;

          &:not(:last-child) {
            @include vw-xsmall-down {
              border-bottom: 1px solid #cfd0d2;
            }
          }
        }
      }
    }
  }
}
