@import "../../styles/media";

.Organizations {
  &__table {
    @include vw-small-up {
      overflow-x: auto;
    }

    table {
      background-color: white;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    &--margined {
      margin-bottom: 24px;

      @include vw-medium-down {
        margin-bottom: 16px;
      }

      @include vw-small-down {
        margin-bottom: 12px;
      }
    }

    @include vw-medium-down {
      align-items: flex-start;
      flex-direction: column;
    }
  }

  &__headerLeft {
    display: flex;
    align-items: center;
  }

  &__headerBack {
    html:root & {
      color: black;
      margin: 0;
      padding: 0 8px 0 0;

      @include vw-small-down {
        height: auto;
        margin-top: -2px;
      }
    }
  }

  &__headerTitle {
    margin: 0;
    height: 36px;

    @include vw-small-down {
      height: auto;
    }
  }

  &__filters {
    display: flex;
    margin-right: 16px;
    flex-grow: 1;

    @include vw-medium-down {
      margin-bottom: 16px;
    }

    @include vw-xsmall-down {
      margin-right: 0;
      width: 100%;
    }
  }

  &__radioButtons {
    margin-right: 16px;

    @include vw-xsmall-down {
      html:root & {
        display: flex;
        margin-right: 0;
        width: 100%;
      }
    }
  }

  &__radioButton {
    @include vw-xsmall-down {
      html:root & {
        align-items: center;
        display: flex;
        justify-content: center;
        height: 40px;
        text-align: center;
        width: 50%;
      }
    }
  }

  &__addButton,
  &__addButtonWrapper {
    @include vw-xsmall-down {
      html:root & {
        height: 40px;
        width: 100%;
      }
    }
  }

  &__search {
    width: 100%;
    max-width: 256px;
  }

  &__column {
    &:not(&--full) {
      width: 50%;

      @include vw-medium-down {
        width: 100%;
      }
    }
  }

  &__saveButton {
    html:root & {
      @include vw-medium-down {
        margin-top: 16px;
      }

      @include vw-small-down {
        margin-top: 0;
      }

      @include vw-xsmall-down {
        width: 100%;
      }
    }

    &:not(&--mobile) {
      @include vw-small-down {
        display: none;
      }
    }

    &--mobile {
      @include vw-small-up {
        html:root & {
          display: none;
        }
      }
    }
  }

  &__form {
    html:root & {
      .ant-card-body {
        align-items: stretch;
        display: flex;
        flex-direction: column;
        height: 100%;

        h2 {
          margin-bottom: 0;
        }
      }

      @include vw-medium-down {
        .ant-card-body {
          padding: 16px 20px;
        }
      }

      @include vw-small-down {
        padding-top: 0;

        .ant-card-body {
          padding: 12px 16px;

          h2 {
            font-size: 16px;
          }
        }

        .ant-divider-horizontal {
          margin: 8px 0;
        }

        .ant-form-item {
          margin-bottom: 12px;

          &.ant-row {
            > * {
              flex: auto;
            }
          }
        }

        .ant-form-item-label {
          padding-bottom: 4px;
        }
      }

      @include vw-xsmall-down {
        .ant-form-item-label {
          text-align: left;
        }

        .ant-radio-group {
          display: flex;
          width: 100%;

          > .ant-radio-button-wrapper {
            display: flex;
            justify-content: center;
            width: 50%;

            @media (max-width: 375px - 1) {
              font-size: 12px;
              padding: 0 4px;
            }
          }
        }
      }
    }
  }
}

.ResponsiveTable {
  $block: &;

  &--organizations {
    @include vw-small-down {
      html:root:root & {
        tr {
          grid-template-columns: repeat(2, 1fr);

          > td {
            &:first-child {
              align-items: center;
              display: flex;
            }

            &:nth-child(2),
            &:nth-child(3) {
              grid-column: 1 / -1;
            }

            &:nth-child(5),
            &:nth-child(6) {
              text-align: right;
            }

            &:last-child {
              border-bottom: 1px solid #cfd0d2;
              grid-column: 2;
              grid-row: 1;
            }

            @include vw-xsmall-down {
              #{$block}__mobileHeading {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}
